import request from '@/utils/request'

export function queryProtocols(parameter) {
  return request({
    url: '/protocol',
    method: 'post',
    data: parameter
  })
}

export function getProtocol(parameter) {
  return request({
    url: '/protocol',
    method: 'get',
    params: parameter
  })
}

export function putProtocol(parameter) {
  return request({
    url: '/protocol',
    method: 'put',
    data: parameter
  })
}