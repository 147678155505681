<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='plus' @click='handleNew()'>新建</a-button>
        <a-divider type='vertical' />
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' :columns='columns' :data='loadData'>
        <span slot='id' slot-scope='text,record'>
          <template>
            <Ellipsis :length='10' tooltip>{{ text }}</Ellipsis>
          </template>
        </span>
        <span slot='status' slot-scope='text,record'>
          <template>
            <a-badge v-if='text=="online"' dot status='success' :text='"上架"' />
            <a-badge v-if='text=="offline"' dot status='default' :text='"下架"' />
          </template>
        </span>
        <span slot='stitle' slot-scope='text,record'>
          <template>
            <Ellipsis :length='10' tooltip>{{ text }}</Ellipsis>
          </template>
        </span>
        <span slot='contentRichTextUrl' slot-scope='text,record'>
          <template>
            <a-space>
              <a-button type='primary' shape='circle' size='small' icon='eye' @click='handleView(text)' />
            </a-space>
          </template>
        </span>
        <span slot='action' slot-scope='text,record'>
          <template>
            <a-space>
              <a @click='handleEdit(record)'>编辑</a>
              <a v-if='record.status=="offline"' @click='handleStatus(record,"online")'>上架</a>
              <a v-if='record.status=="online"' @click='handleStatus(record,"offline")'>下架</a>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryProtocols, putProtocol } from '@/api/protocol'

const columns = [
  {
    title: '#',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: '协议名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'stitle' }
  },
  {
    title: '协议链接',
    dataIndex: 'contentRichTextUrl',
    scopedSlots: { customRender: 'contentRichTextUrl' }
  },
  {
    title: '协议状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ProtocolList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryProtocols(Object.assign(parameter, this.queryParam))
          .then(datum => {
            return datum
          })
      }
    }
  },
  created() {

  },
  methods: {
    handleView(url) {
      window.open(url, '_blank')
    },
    handleNew() {
      this.$router.push({ path: '/protocol/edit' })
    },
    handleEdit(record) {
      this.$router.push({ path: '/protocol/edit', query: { id: record.id } })
    },
    handleStatus(record, status) {
      putProtocol(Object.assign({}, record, { status: status })).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    }
  }
}
</script>
